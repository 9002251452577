import { GradeEntry } from "./socketTypes";
import axios, { AxiosResponse } from "axios";

// Typings for responses.
interface Response {
  error: boolean;
  message: string;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  gender?: string;
  handedness?: string;
  isRep: boolean;
  isAdmin: boolean;
}

interface UserResponse extends Response {
  data: { user: User };
}

interface UserGradesResponse extends Response {
  data: { grades: GradeEntry[] };
}

interface LoginResponse extends Response {
  data: { token: string };
}

interface SuccessResponse extends Response {
  data: { success: boolean };
}

interface NotificationResponse extends Response {
  data: { publicKey: string };
}

// Typings for request bodies.
export interface NewUser {
  email: string;
  auth_method: "local" | "google";
  password: string;
  confirm_password: string;
  first_name: string;
  last_name: string;
  display_name: string;
  gender: string;
  handedness: string;
  terms_version: number | null;
}

// Sets URL based on environment.
export const API_URL: string = process.env.REACT_APP_API || "https://api.gochamp.co.uk";

const expressApi = {
  /**
   * Fetches the signed-in user's attribues from the server using cookie authentication.
   * @returns - object representing the user.
   */
  getUser(): Promise<AxiosResponse<UserResponse>> {
    return axios.get(`${API_URL}/v1/profile`, { withCredentials: true });
  },

  /**
   * Logs in the user at the server an establishes a cookie based session.
   * @param account - authenticating parameters.
   * @returns - a successful response return a JWT token for socket authentication.
   */
  loginLocal(account: {
    username: string;
    password: string;
  }): Promise<AxiosResponse<LoginResponse>> {
    return axios.post(`${API_URL}/v1/login/local`, account, { withCredentials: true });
  },

  /**
   * Logs the current user out of their cookie session at the server.
   * @returns - boolean attribute indicating operation success.
   */
  logout(): Promise<AxiosResponse<SuccessResponse>> {
    return axios.get(`${API_URL}/v1/logout`, { withCredentials: true });
  },

  /**
   * Submits a new user to be added to the database.
   * @param user - object representing new users attributes.
   * @returns - boolean attribute indicating operation success.
   */
  addUser(user: NewUser): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post(`${API_URL}/v1/signup/local`, user, { withCredentials: true });
  },

  /**
   * Submits a request to alter existing user attributes.
   * @param user - object representing the updated user.
   * @returns - boolean attribute indicating operation success.
   */
  editProfile(user: User): Promise<AxiosResponse<SuccessResponse>> {
    return axios.put(`${API_URL}/v1/profile`, user, { withCredentials: true });
  },

  /**
   * Submits a request to alter user's password. Applicable to local sign-in users only.
   * @param password - user's existing password.
   * @param newPassword - user's requested new password.
   * @returns - boolean attribute indicating operation success.
   */
  editPassword(password: string, newPassword: string): Promise<AxiosResponse<SuccessResponse>> {
    return axios.put(
      `${API_URL}/v1/password`,
      { password, newPassword },
      { withCredentials: true }
    );
  },

  /**
   * Triggers the generation and download of a PDF report.
   * @returns - report is downloaded to users browser.
   */
  getReport(): Promise<AxiosResponse> {
    return axios.get(`${API_URL}/v1/report`, { responseType: "blob", withCredentials: true });
  },

  /**
   * Fetches Public key to enable notifications.
   * @returns - string Public notification key.
   */
  getPushPublicKey(): Promise<AxiosResponse<NotificationResponse>> {
    return axios.get(`${API_URL}/v1/notification`, { withCredentials: true });
  },

  /**
   * Creates a subscription to the notifications service.
   * @param subscription - a stringified PushSubscription.
   * @returns - boolean indicating operation success.
   */
  createPushSubscription(subscription: string): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post(`${API_URL}/v1/notification`, { subscription }, { withCredentials: true });
  },

  /**
   * Fetches an array of Grades.
   * @returns - An array of Grades the user has authorization to create a Session for.
   */
  getUserGrades(): Promise<AxiosResponse<UserGradesResponse>> {
    return axios.get(`${API_URL}/v1/grades`, { withCredentials: true });
  },

  /**
   * Endpoint to create a new session.
   * @param grade_id - Id number for the sessions related grade.
   * @param courts - Number of availiable courts.
   * @returns - boolean indicating operation success.
   */
  createSession(grade_id: number, courts: number): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post(`${API_URL}/v1/session`, { grade_id, courts }, { withCredentials: true });
  },

  /**
   * Logs the occurrance of client side errors at the server.
   * @param err - error information to log with the server.
   * @returns - boolean attribute indicating operation success.
   */
  logError(err: any): Promise<AxiosResponse> {
    return axios.post(`${API_URL}/v1/errors`, err, { withCredentials: true });
  },

  /**
   * Attempt to associate a user with a grade. Current user must be a rep.
   * @param email - email address of the user that requires access.
   * @param grade_id - id of the grade to associate the user with.
   */
  authorizeUser(email: string, grade_id: number): Promise<AxiosResponse<SuccessResponse>> {
    return axios.post(`${API_URL}/v1/adduserauth`, { email, grade_id }, { withCredentials: true });
  },
};

export default expressApi;
