// Libraries and Types
import {
  useState,
  useMemo,
  createContext,
  useEffect,
  useCallback,
  Suspense,
  lazy,
  FC,
} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { User } from "./util/api";
import expressApi from "./util/api";

// Components and Styling
import Header from "./components/header/Header";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import Footer from "./components/footer/Footer";
import "./app.scss";

const PolicyPage = lazy(() => import("./pages/policy-page/PolicyPage"));
const HomePage = lazy(() => import("./pages/home-page/HomePage"));
const SignupPage = lazy(() => import("./pages/signup-page/SignupPage"));
const LoginPage = lazy(() => import("./pages/login-page/LoginPage"));
const AboutPage = lazy(() => import("./pages/about-page/AboutPage"));
const GamesAppPage = lazy(() => import("./pages/games-app-page/GamesAppPage"));
const GameLobbyView = lazy(
  () => import("./pages/games-app-page/views/game-lobby-view/GameLobbyView")
);
const PickView = lazy(() => import("./pages/games-app-page/views/pick-view/PickView"));
const QueueView = lazy(() => import("./pages/games-app-page/views/queue-view/QueueView"));
const TableView = lazy(() => import("./pages/games-app-page/views/table-view/TableView"));
const ProfilePage = lazy(() => import("./pages/profile-page/ProfilePage"));

export const UserContext = createContext<{
  user: User | null;
  changeUser: () => void | Promise<void>;
}>({
  user: null,
  changeUser: () => {},
});

const App: FC = () => {
  // Creates state for the user and creates a function that returns the state and state
  // setter that is passed to the context provider for use throughout the application.
  const [user, setUser] = useState<User | null>(null);

  const changeUser = useCallback(async (): Promise<void> => {
    try {
      const { data } = await expressApi.getUser();
      setUser(data.data.user);
      //
    } catch (err) {
      setUser(null);
    }
  }, []);

  const userValue = useMemo(() => ({ user, changeUser }), [user, changeUser]);

  useEffect(() => {
    changeUser();
  }, [changeUser]);

  // If the app loads with a token in the URL query, this is added to localStorage, as
  // the app will have been redirected from an OAuth login.
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  if (token) {
    localStorage.setItem("authToken", JSON.stringify(token));
  }

  return (
    <UserContext.Provider value={userValue}>
      <div className="app">
        <Toaster />
        <Header />
        <main className="app__main">
          <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="games" element={<GamesAppPage />}>
                  <Route path="lobby" element={<GameLobbyView />} />
                  <Route path="pick" element={<PickView />} />
                  <Route path="queue" element={<QueueView />} />
                  <Route path="table" element={<TableView />} />
                </Route>
                <Route path="profile" element={<ProfilePage />} />
                <Route path="policy/*" element={<PolicyPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </main>
        <Footer />
      </div>
    </UserContext.Provider>
  );
};

export default App;
