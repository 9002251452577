// Libraries and Types
import { FC } from "react";
import { Link } from "react-router-dom";

// Components and Styling
import "./footer.scss";

const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className="footer__copyright">
        &copy; GoChamp {new Date().getFullYear()}
        <span className="footer__version">v2.1.0</span>
      </div>
      <Link to="policy/privacy">Privacy</Link>
      <span className="footer__spacer">&</span>
      <Link to="policy/terms">Terms</Link>
    </footer>
  );
};

export default Footer;
