// Libraries and Types
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Components and Styling
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>
);

serviceWorkerRegistration.register();
