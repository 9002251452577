// Libraries and Types
import { Component, ErrorInfo } from "react";
import expressApi from "../../util/api";

// Components and Styling
import "./errorBoundary.scss";

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  async componentDidCatch(_err: Error, errorInfo: ErrorInfo) {
    try {
      await expressApi.logError(errorInfo);
      console.log("Error Logged");
      //
    } catch (err: any) {
      console.log("Error Not Logged");
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error">
          <h1 className="error__title">Something went wrong.</h1>
          <p className="error__text">The error has been logged. Please try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
